import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-6.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import Agenda from "./Main";
import Overview from './schedule/Overview';
import Dec17 from './schedule/Dec17';
import Dec18 from './schedule/Dec18';
import Dec19 from './schedule/Dec19';
const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;
const PrimaryButton = styled(PrimaryButtonBase)(props => [
    tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
    props.buttonRounded && tw`rounded-full`
]);
const PlanDurationSwitcher = tw.div`block w-full max-w-xl sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1 mt-8`;
const SwitchButton = styled.button`
  ${tw`w-1/2 sm:w-32 px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${props => props.active && tw`bg-primary-500 text-gray-100`}
`;
const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto py-10 lg:py-12`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-2/3 -translate-y-1/2`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300`}
`;

const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl py-10 md:py-8 py-20 md:pb-4`;
const LeftColumn = tw.div`relative lg:w-4/12 lg:pr-12 flex-shrink-0 text-center lg:text-left font-bold`;
const RightColumn = tw.div`relative mt-12 lg:w-8/12 lg:mt-0 flex flex-col justify-center border-b border-black`;

const SecondColumn = tw.div`relative lg:w-6/12 flex-shrink-0 text-center lg:text-left font-bold`;
const ThirdColumn = tw.div`relative mt-12 lg:w-6/12 lg:mt-0 flex flex-col justify-center `;
const TwoColumnDual = tw.div`relative flex flex-col lg:flex-row md:items-center lg:w-8/12  `;

export default ({
    subheading = "We will share our conference zoom link and slack channel on 12/14 at 9 p.m. in PST only for the registered users by email. If you do not receive it, please contact us at svcc.conf@svcsi.org",
    heading = "Agenda",
    description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    plans = null,
    primaryButtonText = "Buy Now",
    planDurations = [
        {
            text: "Overview",
            switcherText: "Overview",
            twoColumns: false
        },
        {
            text: "Dec 2",
            switcherText: "Dec 2",
            twoColumns: true
        },
        {
            text: "Dec 3",
            switcherText: "Dec 3",
            twoColumns: false
        },
    ]
}) => {
    const agenda = [
        [],
        [
            {
                "time": "",
                "description": [
                    {
                        "event": "SVCC"
                    }, {
                        "event": "UNISEC"
                    }
                ]
            },
            {
                "time": "08:00 am to 09:00 am",
                "description": [
                    {
                        "title": "Tutorial Sessions",
                        "speaker": "Younghee Park",
                        "description": "Tutorial on "
                    }, {
                        "title": ""
                    }
                ]
            },
            {
                "time": "09:00 am to 10:00 am",
                "description": [
                    {
                        "title": "Keynote Presentation"
                    }, {
                        "title": "UNISEC Keynote"
                    }
                ]
            }
        ],
        [
            {
                "time": "09:00 am to 10:00 am",
                "description":
                    [{
                        "title": "Research Paper Sessions with Keynote speakers",
                        "speaker": "",
                        "description": ""
                    }]
            }
        ],
        [
            {
                "time": "07:00 am to 09:00 am",
                "description": [
                    {
                        "title": "Student Cybersecurity Hackathon",
                        "speaker": "",
                        "description": ""
                    }, {
                        "title": "Student Cybersecurity Hackathon"
                    }
                ]
            }
        ]
    ];

    const [activeDurationIndex, setActiveDurationIndex] = useState(0);
    const currAgenda = agenda[activeDurationIndex];
    return (
        <Container>
            <ContentWithPaddingXl>
                <HeaderContainer>

                    <Heading>{heading}</Heading>
                    <div style={{ textAlign: "center", padding: "0px 30px" }}><b>We will share our conference zoom link and slack channel to the registered users by email soon. If you do not receive it, please contact us at <u><a href="mailto:svcc.conf@svcsi.org">svcc.conf@svcsi.org</a></u>.</b></div>
                    <PlanDurationSwitcher>
                        {planDurations.map((planDuration, index) => (
                            <SwitchButton active={activeDurationIndex === index} key={index} onClick={() => setActiveDurationIndex(index)}>{planDuration.switcherText}</SwitchButton>
                        ))}
                    </PlanDurationSwitcher>
                </HeaderContainer>
                {activeDurationIndex === 0 ?
                    <Overview />
                    : activeDurationIndex == 1 ?
                        <Dec17 /> :
                        activeDurationIndex == 2 ?
                            <Dec18 /> :
                            activeDurationIndex == 3 ?
                                <Dec19 /> :
                                <div className="py-20">
                                    <h2 style={{ textAlign: "center" }} className="py-2 font-bold text-xl">Schedule Coming Soon</h2>
                                    <h2 style={{ textAlign: "center" }} className="font-bold text-sm text-gray-500">Please Check Overview Tab</h2>
                                </div>
                    // <div>
                    //     {currAgenda.map((session, index) => (
                    //         <TwoColumn className="mx-40">
                    //             <LeftColumn>
                    //                 {session.time}
                    //             </LeftColumn>
                    //             {session.description.length === 1 ?
                    //                 <RightColumn>
                    //                     <div className="mb-2">
                    //                         <div style={{ fontWeight: "600", fontSize: "1.2rem" }}>
                    //                             {session.description[0].title}
                    //                         </div>
                    //                         {session.description[0].speaker &&
                    //                             <div style={{ fontWeight: "600", fontSize: "0.9rem" }} className="text-gray-700">
                    //                                 By: {session.description[0].speaker}
                    //                             </div>
                    //                         }
                    //                         {session.description[0].description &&
                    //                             <div style={{ fontSize: "0.9rem" }} className="text-gray-600">
                    //                                 {session.description[0].description}
                    //                             </div>
                    //                         }
                    //                     </div>
                    //                 </RightColumn>
                    //                 :
                    //                 <TwoColumnDual style={{ borderBottom: session.description[0].event ? "" : "1px solid black" }}>
                    //                     <SecondColumn>
                    //                         <div className="mb-2">
                    //                             <div style={{ fontWeight: "600", fontSize: "1.2rem", textAlign: "center" }} className="text-gray-700">
                    //                                 {session.description[0].event}
                    //                             </div>
                    //                             <div style={{ fontWeight: "600", fontSize: "1.2rem" }}>
                    //                                 {session.description[0].title}
                    //                             </div>
                    //                             {session.description[0].speaker &&
                    //                                 <div style={{ fontWeight: "600", fontSize: "0.9rem" }} className="text-gray-700">
                    //                                     By: {session.description[0].speaker}
                    //                                 </div>
                    //                             }
                    //                             {session.description[0].description &&
                    //                                 <div style={{ fontSize: "0.9rem" }} className="text-gray-600">
                    //                                     {session.description[0].description}
                    //                                 </div>
                    //                             }
                    //                         </div>
                    //                     </SecondColumn>
                    //                     <ThirdColumn className="pl-4">
                    //                         <div style={{ fontWeight: "600", fontSize: "1.2rem", textAlign: "center" }} className="text-gray-700">
                    //                             {session.description[1].event}
                    //                         </div>
                    //                         <div className="mb-2">
                    //                             <div style={{ fontWeight: "600", fontSize: "1.2rem" }}>
                    //                                 {session.description[1].title}
                    //                             </div>
                    //                             {session.description[1].speaker &&
                    //                                 <div style={{ fontWeight: "600", fontSize: "0.9rem" }} className="text-gray-700">
                    //                                     By: {session.description[1].speaker}
                    //                                 </div>
                    //                             }
                    //                             {session.description[1].description &&
                    //                                 <div style={{ fontSize: "0.9rem" }} className="text-gray-600">
                    //                                     {session.description[1].description}
                    //                                 </div>
                    //                             }
                    //                         </div>
                    //                     </ThirdColumn>
                    //                 </TwoColumnDual>
                    //             }
                    //         </TwoColumn>
                    //     ))}
                    // </div>
                }
                {/* <div style={{ textAlign: "center", margin: "50px", justifyContent: "space-evenly" }}>
                    <PrimaryButton><a target="_blank" href={"https://drive.google.com/file/d/1oqol5HWJ2ZyCNNPjZnjc7flF21wKajhA/view?usp=sharing"}>Download Conference Brochure</a></PrimaryButton>
                    <PrimaryButton style={{ marginLeft: "10px" }}><a href={"accepted-papers"}>View Accepted Papers</a></PrimaryButton>
                </div> */}
            </ContentWithPaddingXl>

            <DecoratorBlob1 />
            <DecoratorBlob2 />
        </Container >
    );
};
