import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../components/misc/Headings.js";
import AniketKate from "images/keynoteSpeakers/AniketKate.jpg";
import HoumanHomayoun from "images/keynoteSpeakers/HoumanHomayoun.jpg";
import SeanPeisert from "images/keynoteSpeakers/SeanPeisert.jpg";
import AntonioLorena from "images/keynoteSpeakers/AntonioLorena.jpeg";
import Footer from "components/footers/MiniCenteredFooter.js";
import Header from "components/headers/light.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const HeadingMain = tw.h1`font-black text-primary-500 text-2xl md:text-5xl leading-snug max-w-3xl`;
const Heading = tw(HeadingTitle)`mt-4 font-black text-left text-3xl sm:text-2xl lg:text-2xl text-center md:text-left leading-tight`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const TalkDescription = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-16 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const SmallCard = styled.div(props => [
    tw`mt-24 md:flex-col justify-center items-center w-9/12`,
]);
const Image = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const BigImage = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-1000 md:h-1000 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);

const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const PresentationTitle = tw.h4`text-xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
    const cards = [
        {
            imageSrc:
                HoumanHomayoun,
            subtitle: "Hardened RISC-V: Obfuscating RISC-V to Prevent Design Reverse Engineering",
            title: "Houman Homayoun",
            description: "Houman Homayoun is currently an Associate Professor in the Department of Electrical and Computer Engineering at University of California, Davis. He is also the director of National Science Foundation Center for Hardware and Embedded Systems Security and Trust (CHEST). He conducts research in hardware security and trust, applied machine learning and AI, data-intensive computing and heterogeneous computing, where he has published more than 200 technical papers in the prestigious conferences and journals on the subject and directed over $8M in research funding from various industrial sponsors. His work received several best paper awards and nominations in various conferences. His CHEST center received congressional support for research in HW security which was included in 2021 National Defense Authorization Act. Houman served as Member of Advisory Committee, Cybersecurity Research and Technology Commercialization working group in the Commonwealth of Virginia. He is also serving as core group member of hardware security body of knowledge development team supported by the Department of Defense. He was a recipient of 2010 National Science Foundation computing innovation fellow award by CCC/CRA. Since 2017 he has been serving as an Associate Editor of IEEE Transactions on VLSI.",
            talkTitle: "Hardened RISC-V: Obfuscating RISC-V to Prevent Design Reverse Engineering",
            abstract: "This work present hardened RISC-V which can not be reverse engineering.",
            isOnlySpeaker: true,
        },
        {
            imageSrc: SeanPeisert,
            subtitle: "Privacy-Preserving Data Analysis for Scientific Discovery and Public Benefit",
            title: "Sean Peisert",
            description:
                "Dr. Sean Peisert leads computer security research and development at Lawrence Berkeley National Laboratory. He is also a full adjunct professor of Computer Science at the University of California, Davis. His current research and development interests cover a broad cross-section of usable and useful computer security and privacy solutions, particularly in enabling secure and privacy-preserving scientific data analysis, improving security in high-performance computing systems, and power grid control systems. He is also chief cybersecurity strategist for CENIC and co-lead of Trusted CI, the NSF Cybersecurity Center of Excellence. Professor Peisert is editor-in-chief of IEEE Security & Privacy; a steering committee member and past general chair of the New Security Paradigms Workshop (NSPW); steering committee member and past program co-chair of the USENIX Workshop on Cyber Security Experimentation and Test (CSET); past chair of the IEEE Computer Society Technical Committee on Security & Privacy. He has led numerous cybersecurity-related working groups, including co-leading the 2012 Dagstuhl Seminar on Organizational Processes for Supporting Sustainable Security. He was honored as a Research Fellow by the Institute for Information Infrastructure Protection, funded by the U.S. Department of Homeland Security, and in 2014, his work received an award from the Director of Science and Technology for the U.S. Office of the Director of National Intelligence (ODNI). Previously, Dr. Peisert was a computer security researcher at the San Diego Supercomputer Center (SDSC) and was a Senior Fellow in the Berkeley Institute for Data Science at UC Berkeley. He received his Ph.D., Masters's, and Bachelors's degrees in Computer Science from UC San Diego.",
            talkTitle: "Privacy-Preserving Data Analysis for Scientific Discovery and Public Benefit",
            abstract: "Data useful to science is not shared as much as it should or could be, particularly when that data contains sensitivities of some kind.  There are many reasons why data may not be shared, including laws and regulations related to personal privacy or national security, or because data is considered a proprietary trade secret.  And yet, as emphasized widely in scientific communities, finding ways to make sensitive data available is vital for advancing scientific discovery and public policy. This talk discusses emerging security and privacy techniques that can help to surmount many of the obstacles to effectively leveraging sensitive scientific data.",
            isOnlySpeaker: true,
        },
        {
            imageSrc:
                AniketKate,
            subtitle: "",
            title: "Aniket Kate",
            description:
                "Professor Aniket Kate is an Associate Professor in the computer science department at Purdue University. He is an applied cryptographer and a privacy researcher. His research builds on and expands applied cryptography, distributed computing, and data-driven analysis to solve security/privacy problems in decentralized environments. His current projects focus on communication freedom and distributed ledgers (or blockchains). He is a recipient of the NSF CAREER Award for 2019. Before joining Purdue in 2015, he was a junior faculty member at Saarland University, Germany.He completed his postdoctoral fellowship at Max Planck Institute for Software Systems(MPI- SWS), Germany.He has received his PhD from the University of Waterloo, Canada, and his masters from IIT - Bombay, India.",
            talkTitle: "Rethinking Consensus for Fault-Tolerant CPS/IoT",
            abstract: "Motivated by the demand for Byzantine fault-tolerance in cyber-physical systems (CPS) and Internet of things (IoT) environments, this talk explores the energy efficiency of state machine replication (SMR) protocols. The state-of-the-art distributed consensus solutions try to solely reduce the latency/communication overhead and naively employing these that are not optimized for energy efficiency reduces the lifetime of CPS/IoT, and we will explore energy efficiency for the consensus problem",
            isOnlySpeaker: true,
        },
        {
            imageSrc: AntonioLorena,
            subtitle: "Integrate OT Risk, Human Discretionary and AI is possible!",
            title: "Antonio Mauro & Lorena Manco",
            description2:
                "Mrs. Manco has over 15 years of experience in cyber risk, teaching, human resources, compliance and data privacy. During her career, she was team leader in national and international company with specific focus also in cyber risk analysis and risk evaluation in insurance market. Bachelor Degree in Law - Science of Legal Services - dissertation in 'Profiling and Warker's Automated Data Processing: protective measures and IT - legal profiles'. This  research project included data profiling and automatic decision in the company, the protection digital human right in new technology to obtain the compliance in data protection (GDPR, Privacy Act, etc) and Master Degree in digital law in the new technologies, legal informatics and legal data processing. She has strong skill and passion for the Human Capital and Human Behavior and Artificial Intelligence.Mrs.Manco archived also a specialization course in Data Protection and Data Governance in cyber security, focus on risk analysis between Human Factor and Artificial Intelligence.",
            description1: "Mr. Mauro has over 20 years of cybersecurity international carrier with various executive experiences across key high technology markets. He developed research projects and prototypes of high technologies(Gov’t included) and he's also the inventor of two patents for the forensic investigations in the IoT devices and Vulnerability Assessment in the Industrial Ecosystem. He spent main part of his career working for Government Agencies(Carabinieri Corps, DoD, DHS, NATO) in preempting, investigating and remediating government cyber espionage, cyber security and terrorism.During his career, he was CISO and other executive experiences in cyber risk, intelligence, digital forensics, and compliance. Mr.Mauro is member of several government and academic organizations; among other: permanent member at the Italy National Security Observatory, member at the New York/ New Jersey Electronic Crimes Task Force - U.S.Secret Service, member of the Scientific Committee for the UNI 11506, member and expert of the Cybersecurity Task Force of the Strategic Forum for Important Projects of Common European Interest(IPCEI), San Francisco Bay Area Chapter of InfraGard(InfraGard is a partnership between the FBI and members of the private sector to the protection of Critical Infrastructure).",
            talkTitle: "Integrate OT Risk, Human Discretionary and AI is possible!",
            abstract: "The topic is divided into two main sections and combines multiple disciplines such as cybersecurity, artificial intelligence, and human factor. The first section it’s designed to have an overview about Operational Technology (OT), include also an introduction of IoT and ICS systems, ICS protocols and standards and difference from IT. We also touch on the highlight vulnerabilities in ICS systems, the risks, and the mitigation process. All information in this section is influenced by ICSJWG, CISA, and NIST. The second section integrates the human discretionary and Artificial Intelligence in the Risk Analysis processes in order to reduce the human impact and improve the quality of choices, increase the quality of decisions and support the decision-makers. The goal of this section is to explain our point of view to reduce the human impact in the risk analysis activities and processes. We also approach the human and technology revolution in order to use AI to support humans in compliance and prediction activities.",
            isOnlySpeaker: false,
        }

        // {
        //   imageSrc:
        //     "https://images.unsplash.com/photo-1509824227185-9c5a01ceba0d?ixlib=rb-1.2.1&auto=format&fit=crop&w=658&q=80",
        //   subtitle: "Exclusive",
        //   title: "Lollapalooza, Manhattan",
        //   description:
        //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        //   url: "https://timerse.com"
        // }
    ];

    return (
        <AnimationRevealPage>
            <Header />
            <Container>
                <SingleColumn>

                    <HeadingMain>Keynote Speakers</HeadingMain>

                    <Content>
                        {cards.map((card, i) => (
                            <div>
                                <Card key={i} reversed={i % 2 === 1}>
                                    {card.isOnlySpeaker && <Image style={{ height: "480px", width: "390px" }} imageSrc={card.imageSrc} />}
                                    {!card.isOnlySpeaker && <BigImage style={{ height: "900px", width: "390px" }} imageSrc={card.imageSrc} />}
                                    <Details>
                                        {/* <Subtitle>{card.subtitle}</Subtitle> */}
                                        <Title>{card.title}</Title>
                                        {card.isOnlySpeaker && <Description>{card.description}</Description>}
                                        {!card.isOnlySpeaker &&
                                            <div>
                                                <Description>
                                                    {card.description1}
                                                </Description>
                                                <Description>
                                                    {card.description2}
                                                </Description>
                                            </div>
                                        }
                                    </Details>
                                </Card>
                                <br />
                                {card.talkTitle && <Heading><HighlightedText>Talk Title </HighlightedText> {card.talkTitle} </Heading>}
                                {card.abstract && <TalkDescription><b>Abstract : </b> <i> {card.abstract} </i></TalkDescription>}
                                {/* <SmallCard>
                                <PresentationTitle>{card.title}</PresentationTitle>
                                <Description>{card.description}</Description>
                            </SmallCard> */}
                            </div>
                        ))}
                    </Content>
                </SingleColumn>
                <SvgDotPattern1 />
                <SvgDotPattern2 />
                <SvgDotPattern3 />
                <SvgDotPattern4 />
            </Container>
            <Footer />
        </AnimationRevealPage>
    );
};
