import React from "react";
import { Link as Linker } from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";
import { Container as ContainerBase } from "components/misc/Layouts.js";
import logo from "../../images/purple-svcc.png";
import svcsilogo from "../../images/svsci-logo.png";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";


const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8  -mb-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;

const LogoContainer = tw.div`flex items-center justify-center`;
const LogoImg = tw.img`w-1/3`;
const NewLogo = tw.img`w-3/12`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const PolicyLinksContainer = tw.div`mt-1 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const PolicyLink = tw.a` transition duration-300 mt-1 mx-4`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`;
export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} />
            {/* <LogoText>Treact</LogoText> */}
          </LogoContainer>
          <span style={{ color: "#6415ff" }}>In Collaboration with</span>
          <a href="https://www.svcsi.org/" >
            <LogoContainer>
              <NewLogo src={svcsilogo} />
            </LogoContainer>
          </a>
          <LinksContainer>
            <Link href="/">Home</Link>
            <Link href="/callforpapers">Call For Papers</Link>

            <Link href="/datathon">Datathon</Link>
            {/* <Link href="/speakers">Speakers</Link>
            <Link href="/unisec">UNISEC</Link>
            <Link href="/hackathon">Hackathon</Link> */}
            {/* <Link href="/sponsors">Sponsors</Link> */}
          </LinksContainer>
          {/* <SocialLinksContainer>
            <SocialLink href="https://facebook.com">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer> */}
          <CopyrightText>
            &copy; All Copyrights reserved for <a href="https://www.svcsi.org/" >Silicon Valley Cybersecurity Institute (SVCSI)</a>.
          </CopyrightText>
          <PolicyLinksContainer>
            <PolicyLink href="https://www.svcsi.org/terms">Terms of Use</PolicyLink>
            <PolicyLink href="https://www.svcsi.org/privacy">Privacy Policy</PolicyLink>
          </PolicyLinksContainer>
          <CopyrightText>
            You can reach out to us via email at <a href="mailto:svcc.conf@svcsi.org" style={{ color: "#6415ff" }}>svcc.conf@svcsi.org</a>
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
