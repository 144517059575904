import React from "react";
import tw from "twin.macro";
import Head from "./Head";
import Header from "../../components/headers/light.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { ReactComponent as QuotesLeftIcon } from "../../images/quotes-l.svg";
import { ReactComponent as QuotesRightIcon } from "../../images/quotes-r.svg";
import { LinkedinFilled } from '@ant-design/icons';
import { Row, Col } from "antd";

const HeroContainer = tw.div`z-20 relative max-w-screen-xl mx-auto`;

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const QuoteContainer = tw.div`relative p-6 md:p-8 lg:p-10 mt-4 md:mt-0`;
        const Quote = tw.blockquote`text-center md:text-left font-medium text-xl lg:text-lg xl:text-lg`;
        const QuotesLeft = tw(QuotesLeftIcon)`w-8 h-8 lg:w-10 lg:h-10 text-primary-500 absolute top-0 left-0`;
        const QuotesRight = tw(QuotesRightIcon)`w-8 h-8 lg:w-10 lg:h-10 text-primary-500 absolute bottom-0 right-0`;
        const subHead = "2rem";
        return (
            <AnimationRevealPage>
                <HeroContainer>
                    <Header />
                    <div className="mx-2">
                        <h1 style={{ "color": "#6415FF", fontSize: "2.5rem", fontWeight: "700", textAlign: "center", marginTop: "40px", marginLeft: "0px" }}>
                            CONFERENCE ORGANIZERS
                        </h1>
                        <Row>
                            <Col
                                className="md:ml-24 lg:ml-24 xl:ml-24"
                            >
                                <div>
                                    <h1 style={{ "color": "#6415FF", fontSize: "1.70rem", fontWeight: "700", textAlign: "left", marginTop: "20px", marginLeft: "0px" }}>
                                        General Chair
                                    </h1>
                                    <ul role="list" className="chair-name ml-3 text-slate-900 mb-6">
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Divyesh Jadav, IBM Research</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Younghee Park, San Jose State University</span></li>
                                    </ul>
                                </div>
                                <div>
                                    <h1 style={{ "color": "#6415FF", fontSize: "1.70rem", fontWeight: "700", textAlign: "left", marginTop: "20px", marginLeft: "0px" }}>
                                        Program Chair
                                    </h1>
                                    <ul role="list" className="chair-name ml-3 text-slate-900 mb-6">
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Sang-Yoon Chang, University of Colorado Colorado Spring</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Luis Bathen, IBM Research</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Fabio Di Troia, San Jose State University</span></li>
                                    </ul>
                                </div>
                                <div>
                                    <h1 style={{ "color": "#6415FF", fontSize: "1.70rem", fontWeight: "700", textAlign: "left", marginTop: "20px", marginLeft: "0px" }}>
                                        Publicity Chair
                                    </h1>
                                    <ul role="list" className="chair-name ml-3 text-slate-900 mb-6">
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Sara Tehranipoor, Santa Clara University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Wei Yan, Clarkson University</span></li>
                                    </ul>
                                </div>
                                <div>
                                    <h1 style={{ "color": "#6415FF", fontSize: "1.70rem", fontWeight: "700", textAlign: "left", marginTop: "20px", marginLeft: "0px" }}>
                                        Publication Chair
                                    </h1>
                                    <ul role="list" className="chair-name ml-3 text-slate-900 mb-6">
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Thomas Austin, San Jose State University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Alex Nelson, NIST</span></li>
                                    </ul>
                                </div>
                                <div>
                                    <h1 style={{ "color": "#6415FF", fontSize: "1.70rem", fontWeight: "700", textAlign: "left", marginTop: "20px", marginLeft: "0px" }}>
                                        Registration Chair
                                    </h1>
                                    <ul role="list" className="chair-name ml-3 text-slate-900 mb-6">
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Sang-soo Lee, San Jose State University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Michael Tjebben, San Jose State University</span></li>
                                    </ul>
                                </div>
                                <div>
                                    <h1 style={{ "color": "#6415FF", fontSize: "1.70rem", fontWeight: "700", textAlign: "left", marginTop: "20px", marginLeft: "0px" }}>
                                        Poster Chair
                                    </h1>
                                    <ul role="list" className="chair-name ml-3 text-slate-900 mb-6">
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Gokay Saldamli , San Jose State University</span></li>
                                    </ul>
                                </div>
                                <div>
                                    <h1 style={{ "color": "#6415FF", fontSize: "1.70rem", fontWeight: "700", textAlign: "left", marginTop: "20px", marginLeft: "0px" }}>
                                        Special Session Chair
                                    </h1>
                                    <ul role="list" className="chair-name ml-3 text-slate-900 mb-6">
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Nima Karimian , San Jose State University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Hossein Sayadi , California State University, Long Beach</span></li>
                                    </ul>
                                </div>
                                <div>
                                    <h1 style={{ "color": "#6415FF", fontSize: "1.70rem", fontWeight: "700", textAlign: "left", marginTop: "20px", marginLeft: "0px" }}>
                                        Hackathon Chair
                                    </h1>
                                    <ul role="list" className="chair-name ml-3 text-slate-900 mb-6">
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Jorjeta Jetcheva , San Jose State University</span></li>
                                    </ul>
                                </div>
                                <div>
                                    <h1 style={{ "color": "#6415FF", fontSize: "1.70rem", fontWeight: "700", textAlign: "left", marginTop: "20px", marginLeft: "0px" }}>
                                        Technical Program Committee
                                    </h1>
                                    <ul role="list" className="chair-name ml-3 text-slate-900 mb-6">
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Vikrant Nanda,	Google Inc.</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>  Subhash Lakshminarayana,	University of Warwick</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}> Malek Ben Salem,	Accenture Inc.</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}> Sang Kil Cha,	KAIST</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}> Harshan Jagadeesh,	Indian Institute of Technology Delhi</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}> Chang-Wu Chen,	imToken</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}> Carlos Rubio-Medrano,	Texas A&M University Corpus Christi</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}> Eul Gyu Im,	Hanyang University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}> Lei Xu,	University of Texas Rio Grande Valley</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}> Xiaoyan (Sherry) Sun,	California State University, Sacramento</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}> Tai M. Chung,	Sungkyunkwan University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}> Liudong Xing,	University of Massachusetts Dartmouth</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}> Sangwon Hyun,	Myongji University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}> Daisuke Mashima,	Illinois at Singapore Pte Ltd, Singapore</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}> Sung Lee,	VMWare</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}> Sandra Céspedes,	University of Chile</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}> Mohammadreza Ashouri,	University of Potsdam</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}> Francesco Mercaldo,	Università degli Studi del Molise</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}> Carlos Rubio-Medrano,	Arizona State University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>  Wenjun Fan,	University of Colorado, Colorado Springs</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>  Ihor Vasyltsov,	Samsung Electronics</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>  Wei Yan,	clarkson university</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>  Ahyoung Lee,	Kennesaw State University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>  Daisuke Mashima,	Advanced Digital Sciences Center</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>  Thomas Austin,	San Jose State University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>  Chang-Wu Chen,	imToken</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>  Hsiang-Jen Hong,	University of Colorado, Colorado Springs</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>   Lei Xu, 	University of Texas Rio Grande Valley</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>   Hossein Sayadi,	California State University, Long Beach</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>  Liudong Xing,	University of Massachusetts Dartmouth</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>  Hyoungshick Kim,	Sungkyunkwan University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>  Attila Altay Yavuz, 	University  of South Florida</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>  Donghyun (David) Kim,	Georgia State University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>  Jinoh Kim,	Texas A&M University, Commerce</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>   Hongxin Hu,	Clemson University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>  Jorjeta Jetcheva,	San Jose State University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>  Tamzidul Hoque,	University of Kensas</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>  Arman Roohi,	University of Nebraska at Lincoln</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}> Paul Wortman,	University of Connecticut</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>   Samah Saeed, 	The City College of New York</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>  Sara Tehranipoor,	Santa Clara University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>  Prabha Sundaravadivel,	The University of Texas at Tyler</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>  Samaneh Ghandali,	Google</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>  Nima Karimian,	San Jose State University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>  Kohei Shiomoto,	Tokyo city university</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>  Qiong Zhang,	Fujitsu Lab</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>  Mohammad Husain,	California State Polytechnic University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>  Daehee Seo,	SangMyung University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>  Zhang Tianwei,	Nanyang Technological University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>  Gokay Saldamli,	San Jose State University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}> YoungHyun Oh, IBM, USA</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}> TJ O'Connor, Florida Institute of Technology</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}> Hongda Li, Palo Alto Networks Inc.</span></li>
                                    </ul>
                                </div>
                                <div>
                                    <h1 style={{ "color": "#6415FF", fontSize: "1.40rem", fontWeight: "700", textAlign: "left", marginTop: "20px", marginLeft: "0px" }}>
                                        Webmaster
                                    </h1>
                                    <ul role="list" className="chair-name ml-3 text-slate-900 mb-6">
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Monil Sakhidas,	San Jose State University</span></li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </HeroContainer>
                <Footer />
            </AnimationRevealPage >
        );
    }
}

export default Main;