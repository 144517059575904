import React from "react";
import Speaker1 from "./Speaker1.js";
import Speaker2 from "./Speaker2.js";
import Head from "./Head";
import Header from "../../components/headers/light.js";
import tw from "twin.macro";
import { Modal, Button, Row, } from 'antd';
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/MiniCenteredFooter";
import SpeakerCols from "./SpeakerCols";
import { ReactComponent as QuotesLeftIcon } from "../../images/quotes-l.svg";
import { ReactComponent as QuotesRightIcon } from "../../images/quotes-r.svg";
import { LinkedinFilled } from '@ant-design/icons';
import { Popover } from "antd";
import { PlusCircleTwoTone } from "@ant-design/icons";

const content =
    [
        {
            "title": "Michele D. Guel",
            "description": "Michele D. Guel has been an avid speaker, influencer and evangelist in the cybersecurity industry for 31 years. She joined Cisco in March 1996 as the founding member of Cisco’s internal security team. During her 24+ years at Cisco, she has worked in all facets of data, information, network security, IoT operational security excellence and has established many “firsts” at Cisco. In her current role in the Office of the CTO for the Security Business Group, she is focused on data security and privacy strategies for Cisco products and offerings. In 2010, Michele was promoted to Distinguished Engineer (DE), one of 9 female DEs across Cisco today. In 2014 she co-founded Cisco’s Women in Cybersecurity Community which focuses on developing the next generation of women cybersecurity leaders. In 2016 she received the prestigious Anita Borg’s 2016 Women of Vision Technology Leadership Award."
        },
        {
            "title": "Melike Erol-Kantarci",
            "description": "Melike Erol-Kantarci is Tier 2 Canada Research Chair in AI-enabled Next-Generation Wireless Networks and associate professor at the School of Electrical Engineering and Computer Science at the University of Ottawa. She is the founding director of the Networked Systems and Communications Research (NETCORE) laboratory. She has over 140 peer-reviewed publications which have been cited over 5000 times and she has an h-index of 36. She has acted as the general chair and technical program chair for many international conferences and workshops; most recently TPC Chair for IEEE CAMAD 2020, track chair for IEEE Globecom 2020, IEEE SmartGridComm 2020 and IEEE CCNC 2021. Her main research interests are AI-enabled wireless networks, 5G and 6G wireless communications, smart grid, electric vehicles, Internet of things and cybersecurity. She is a senior member of the IEEE."
        }
    ];
const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const HeroContainer = tw.div`z-20 relative max-w-screen-xl mx-auto`;

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            index: 0
        };
    }
    handleModal = (open, index) => {
        this.setState({
            open,
            index
        });
    };

    render() {
        const QuoteContainer = tw.div`relative p-6 md:p-8 lg:p-10 mt-4 md:mt-0`;
        const Quote = tw.blockquote`text-center md:text-left font-medium text-xl lg:text-lg xl:text-lg`;
        const QuotesLeft = tw(QuotesLeftIcon)`w-8 h-8 lg:w-10 lg:h-10 text-primary-500 absolute top-0 left-0`;
        const QuotesRight = tw(QuotesRightIcon)`w-8 h-8 lg:w-10 lg:h-10 text-primary-500 absolute bottom-0 right-0`;
        return (
            <div>
                <HeroContainer>
                    <AnimationRevealPage>
                        <Header className="mb-2" />
                        {/* <Head /> */}
                        {/* <div style={{ backgroundColor: "rgb(237, 242, 247)" }} className="p-4 mt-4">
                            <QuoteContainer className="mx-4 p-6" style={{ backgroundColor: "rgb(237, 242, 247)", borderRadius: "5px" }}>
                                <Quote>
                                    <QuotesLeft />
                                    <div style={{ marginTop: "15px", textAlign: "center" }}>
                                        <i>
                                            This forum aims to inspire the next generation of women and Underrepresented groups by creating a learning community of students, professors, and industry professionals in Silicon Valley and beyond. It connects future women cybersecurity practitioners from campus with leading professionals in Silicon Valley. Students are inspired by a clear path forward through meaningful engagement and practical guidance. Here are our objectives for this forum.
                                    <ul>
                                                <li>
                                                    Bring together women and minorities in cybersecurity for learning and networking.
                                         </li>
                                                <li>
                                                    Learn from women/ minority leaders for career insights in cybersecurity.
                                        </li>
                                            </ul>
                                        </i>
                                    </div>
                                    <QuotesRight />
                                </Quote>
                            </QuoteContainer>
                        </div> */}
                        <div className="mb-8">
                            {/* <div>
                                <h1 style={{ "color": "#6415FF", fontSize: "2rem", fontWeight: "700", textAlign: "left", marginTop: "20px", marginLeft: "0px" }}>
                                    Chairs for Underrepresented Groups in Cybersecurity
                            </h1>
                                <ul role="list" className="chair-name ml-3">
                                    <li data-icon="▸"><span style={{ fontSize: "1.35rem", fontWeight: "600" }}>Younghee Park, San Jose State University</span></li>
                                    <li data-icon="▸"><span style={{ fontSize: "1.35rem", fontWeight: "600" }}>Sara Tehranipoor, Santa Clara University</span></li>
                                </ul> */}
                            {/* <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-2 gap-24 px-48 mt-4">
                                <div class="flex flex-col items-center justify-center bg-white p-4 shadow rounded-lg">
                                    <div class="inline-flex shadow-lg border border-gray-200 rounded-full overflow-hidden h-40 w-40">
                                        <img src="http://www.pngmart.com/files/10/Female-User-Account-PNG-Transparent-Image.png"
                                            alt=""
                                            class="h-full w-full" />
                                    </div>
                                    <h2 class="mt-4 font-bold text-xl">Younghee Park</h2>
                                    <h6 class="mt-2 text-sm font-medium">San Jose State University</h6>
                                </div>
                                <div class="flex flex-col items-center justify-center bg-white p-4 shadow rounded-lg">
                                    <div class="inline-flex shadow-lg border border-gray-200 rounded-full overflow-hidden h-40 w-40">
                                        <img src="http://www.pngmart.com/files/10/Female-User-Account-PNG-Transparent-Image.png"
                                            alt=""
                                            class="h-full w-full" />
                                    </div>
                                    <h2 class="mt-4 font-bold text-xl">Sara Tehranipoor</h2>
                                    <h6 class="mt-2 text-sm font-medium">Santa Clara University</h6>
                                </div>
                            </div> 
                            </div>*/}
                            {/* <div>
                                <h1 style={{ "color": "#6415FF", fontSize: "2rem", fontWeight: "700", textAlign: "left", marginTop: "20px", marginLeft: "0px" }}>
                                    Distinguished Speakers
                            </h1>

                                <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 gap-24 xl:px-48 lg:px-48 md:px-6 mt-4">

                                    <div class="flex flex-col items-center justify-center bg-white p-4 shadow rounded-lg">

                                        <div class="inline-flex shadow-lg border border-gray-200 rounded-full overflow-hidden h-40 w-40 speaker-border">

                                            <img src="https://images.squarespace-cdn.com/content/v1/5e67cd61d3d039551d9349d2/1599845712860-NJ1Y4WEBUUC8X3DASQMI/ke17ZwdGBToddI8pDm48kNhLVqbMSQe1LATz2gm9H5ZZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7XRK3dMEBRBhUpwuIcb__zrC4pJLHjKaxKSQJ74OELJkLV05aabR_pTgiF7QgOruKFXA9EXPXFMlomY/MGuel_Pic.jpg?format=500w"
                                                alt=""
                                                class="h-full w-full"
                                            />
                                        </div> */}

                            {/* <h2 class="mt-4 font-bold text-2xl">Michele D. Guel</h2> */}
                            {/* <p class="text-xs text-gray-500 text-center mt-3">
                                        <PlusCircleTwoTone onClick={() => this.handleModal(true, 0)} />
                                    </p> */}
                            {/* <p class="text-xs text-gray-500 text-center mt-3">
                                            Michele D. Guel has been an avid speaker, influencer and evangelist in the cybersecurity industry for 31 years. She joined Cisco in March 1996 as the founding member of Cisco’s internal security team. During her 24+ years at Cisco, she has worked in all facets of data, information, network security, IoT operational security excellence and has established many “firsts” at Cisco. In her current role in the Office of the CTO for the Security Business Group, she is focused on data security and privacy strategies for Cisco products and offerings. In 2010, Michele was promoted to Distinguished Engineer (DE), one of 9 female DEs across Cisco today. In 2014 she co-founded Cisco’s Women in Cybersecurity Community which focuses on developing the next generation of women cybersecurity leaders. In 2016 she received the prestigious Anita Borg’s 2016 Women of Vision Technology Leadership Award.
				        </p>
                                        <ul class="flex flex-row mt-4 space-x-2">
                                            <li>
                                                <a href="https://www.linkedin.com/in/michele-d-guel-6992993" target="_blank" class="flex items-center justify-center h-8 w-8 border rounded-full text-gray-800 border-gray-800">
                                                    <LinkedinFilled style={{ fontSize: '16px', color: '#08c' }} />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="flex flex-col items-center justify-center bg-white p-4 shadow rounded-lg">
                                        <div class="inline-flex shadow-lg border border-gray-200 rounded-full overflow-hidden h-40 w-40 speaker-border">
                                            <img src="https://images.squarespace-cdn.com/content/v1/5e67cd61d3d039551d9349d2/1599845677650-97PD91WTW9PIP78J8TI8/ke17ZwdGBToddI8pDm48kM9dCTH6_dwnO5G0WDer2d1Zw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7XRK3dMEBRBhUpyhfwEu9olAVXLG3Z-x93CBfy4FkWxLyF7cOcfmS_Duxt1tWRUsbfLR12nGZfh1cfY/Melike_GB.jpg?format=500w"
                                                alt=""
                                                class="h-full w-full" />
                                        </div>
                                        <h2 class="mt-4 font-bold text-2xl">Melike Erol-Kantarci</h2>
                                        <p class="text-xs text-gray-500 text-center mt-3">
                                            Melike Erol-Kantarci is Tier 2 Canada Research Chair in AI-enabled Next-Generation Wireless Networks and associate professor at the School of Electrical Engineering and Computer Science at the University of Ottawa. She is the founding director of the Networked Systems and Communications Research (NETCORE) laboratory. She has over 140 peer-reviewed publications which have been cited over 5000 times and she has an h-index of 36. She has acted as the general chair and technical program chair for many international conferences and workshops; most recently TPC Chair for IEEE CAMAD 2020, track chair for IEEE Globecom 2020, IEEE SmartGridComm 2020 and IEEE CCNC 2021. Her main research interests are AI-enabled wireless networks, 5G and 6G wireless communications, smart grid, electric vehicles, Internet of things and cybersecurity. She is a senior member of the IEEE.
				        </p>
                                        <ul class="flex flex-row mt-4 space-x-2">
                                            <li>
                                                <a href="https://ca.linkedin.com/in/melikeerolkantarci" target="_blank" class="flex items-center justify-center h-8 w-8 border rounded-full text-gray-800 border-gray-800">
                                                    <LinkedinFilled style={{ fontSize: '16px', color: '#08c' }} />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div> */}
                            {/* <Speaker1 />
                            <Speaker2 /> */}
                            {/* </div > */}
                            <div class="bg-gray-100 pt-10 mt-4">
                                <div class="mx-auto max-w-6xl">
                                    <div class="p-2 bg-gray-100 rounded">
                                        <div class="flex flex-col md:flex-row">
                                            <div class="md:w-5/12 p-4 text-sm">
                                                <div class="text-3xl" style={{ "color": "#064b60", fontSize: "2.5rem", fontWeight: "700" }}>CALL FOR POSTERS</div>
                                                <div class="my-2" style={{ fontSize: "1.1rem" }}>The deadline will be on <span className="text-red-600">October 10 at 11:59 P.M. PDT.</span>
                                                </div>
                                                <div class="text-sm text-gray-600">Here is the abstract submission link. https://easychair.org/conferences/?conf=svcc2021</div>
                                                <div className="mt-6">
                                                    <PrimaryButton><a href="https://easychair.org/conferences/?conf=svcc2021" target="_blank">Submit</a></PrimaryButton>
                                                </div>
                                            </div>
                                            <div class="md:w-7/12">
                                                <div class="p-4" style={{ textAlign: "left", textAlign: "justify", textJustify: "interWord" }}>
                                                    We invite researchers to submit three- or four-page poster papers related to cybersecurity topics with a maximum 100-word abstract in English by using the CCIS template to submit your paper.  Please refer to the submission instruction (https://svcc2021.svcsi.org/submissions). The poster is not anonymous submission, so the authors must present their names, affiliation(s), and other identifying information from the header in the manuscript. The title must start with “POSTER: Paper Title.”. The authors will present their work during the poster session virtually. Note that the poster paper will not be included in the conference proceeding due to the minimum requirement of pages at Springer. If you want to include your poster paper in the conference proceeding, you need to extend your poster paper up to 12 pages by the camera-ready deadline (November 15, 2021).
                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h1 style={{ "color": "#6415FF", fontSize: "2.25rem", fontWeight: "700", textAlign: "left", marginTop: "20px", marginLeft: "10px", marginBottom: "20px" }}>
                                    Important Dates
                        </h1>
                                <div className="px-24">
                                    <div class="lg:flex md:flex shadow rounded-lg border border-gray-400">
                                        <div class="bg-blue-600 rounded-lg lg:w-3/12 py-4 block h-full shadow-inner" style={{ backgroundColor: "#064b60" }}>
                                            <div class="text-center tracking-wide">
                                                <div class="text-white font-bold text-4xl ">10</div>
                                                <div class="text-white font-normal text-2xl">October 10</div>
                                            </div>
                                        </div>
                                        <div class="w-full  lg:w-9/12 xl:w-full px-1 bg-white py-5 lg:px-2 lg:py-2 tracking-wide">
                                            <div class="font-semibold text-gray-800 text-3xl text-center lg:text-center px-2">
                                                Submission Deadline
                                </div>
                                            <div class="font-semibold text-red-500 text-xl text-center lg:text-center px-2">(Hard Deadline)</div>
                                        </div>
                                    </div>
                                    <div class="lg:flex md:flex shadow rounded-lg border border-gray-400 my-2">
                                        <div class="bg-blue-600 rounded-lg lg:w-3/12 py-4 block h-full shadow-inner" style={{ backgroundColor: "#064b60" }}>
                                            <div class="text-center tracking-wide">
                                                <div class="text-white font-bold text-4xl ">20</div>
                                                <div class="text-white font-normal text-2xl">October</div>
                                            </div>
                                        </div>
                                        <div class="w-full  lg:w-9/12 xl:w-full px-1 bg-white py-5 lg:px-2 lg:py-2 tracking-wide">
                                            <div class="font-semibold text-gray-800 text-3xl text-center lg:text-center px-2">
                                                Acceptance Notification
                                </div>
                                        </div>
                                    </div>
                                    <div class="lg:flex md:flex shadow rounded-lg border border-gray-400 ">
                                        <div class=" rounded-lg lg:w-3/12 py-4 block h-full shadow-inner" style={{ backgroundColor: "#064b60" }}>
                                            <div class="text-center tracking-wide">
                                                <div class="text-white font-bold text-4xl ">15</div>
                                                <div class="text-white font-normal text-2xl">November</div>
                                            </div>
                                        </div>
                                        <div class="w-full  lg:w-9/12 xl:w-full px-1 bg-white py-5 lg:px-2 lg:py-2 tracking-wide">
                                            <div class="font-semibold text-gray-800 text-3xl text-center lg:text-center px-2">
                                                Camera-Ready Deadline
                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                        <Footer />
                    </AnimationRevealPage>
                </HeroContainer>
            </div >
        );
    }
}

export default Main;