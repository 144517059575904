import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "../headers/light.js";
import Footer from "../footers/MiniCenteredFooter.js";
import { SectionHeading } from "../misc/Headings";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${'' /* ${tw`mt-2 leading-loose`} */}
    ${tw`mt-2 leading-normal`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-outside`}
    li {
      ${tw`ml-12`}
      p {
        ${'' /* ${tw`mt-0 inline leading-normal`} */}
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "IOT CYBERSECURITY HACKATHON FOR STUDENTS" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container style={{ textAlign: "justify", textJustify: "interWord" }}>
        <ContentWithPaddingXl>
          <div className="">
            <h1 style={{ "color": "#6415FF", fontSize: "2.5rem", fontWeight: "700", textAlign: "center", marginTop: "0px", marginLeft: "0px" }}>
              Instructions for Authors
                        </h1>
            {/* <b><i>All the deadlines are based in PDT</i></b> */}
          </div>
          <Text style={{ fontSize: "16px", marginTop: "20px" }}>
            <p>
              The accepted papers in SVCC 2021 will be published through the Communications in Computer and Information Science (CCIS) book at Springer.
              Please use the CCIS template to submit your camera version at Springer. Here is the link to the CCIS web page <a href="http://www.springer.com/series/7899" target="blank" className="text-orange-600 font-semibold hover:underline">http://www.springer.com/series/7899</a> and you can refer to the CCIS templates there.
            </p>
            <p>
              Please also read the “Author Instructions” at <a href="http://www.springer.com/gp/computer-science/lncs/conference-proceedings-guidelines" target="blank" className="text-orange-600 font-semibold hover:underline">http://www.springer.com/gp/computer-science/lncs/conference-proceedings-guidelines</a>.
            </p>
            <p>
              The camera-ready version of your paper is due on <span className="text-red-600 font-semibold">November 15, 2021</span>.
            </p>
            <p>
              Please upload the camera-ready version of your paper with your final Latex files and your copyright form on the Google form.
            </p>
            <p>
              <b><u>Your final camera-ready papers</u></b> must select one of the following two different types of papers:
            </p>
            <ul>
              <li className="underline">Regular papers: Up to <span className="text-red-600 font-semibold">16 pages</span> in the CCIS format.</li>
              <li className="underline">Short papers: <span className="text-red-600 font-semibold">12 pages</span> in the CCIS format.</li>
              <li className="underline">Poster Abstract: <span className="text-red-600 font-semibold">12 pages</span> in the CCIS Format (only if you wish to include your poster in the conference proceeding (CCIS book) at Springer.) Note that you must register for this conference as <span className="font-semibold underline">a paper author rate</span> instead of the poster author registration rate due to the Open Access Publication Processing Cost.)</li>
            </ul>
            <p>
              Please note that you will need an ORCID ID for the authors. Springer provides details about this at <a href="https://www.springernature.com/gp/researchers/orcid" target="_blank" className="text-orange-600 font-semibold hover:underline">https://www.springernature.com/gp/researchers/orcid</a>.
            </p>
            <p>
              Here is Springer's copyright form with the SVCC details pre-filled form for Open Access:
            </p>
            <div style={{ textAlign: "left", justifyContent: "space-around" }}>
              <PrimaryButton><a target="_blank" href={"https://drive.google.com/file/d/1h4rHr6SytGC-JRc-ZAq2Do3t9HTVEsje/view?usp=sharing"}>Download Copyright Form (Springer)</a></PrimaryButton>
              &nbsp;&nbsp;&nbsp;
              <PrimaryButton><a target="_blank" href={"https://forms.gle/9mvB3q2WLCGfJdD68"}>Fill out this Google Form</a></PrimaryButton>
            </div>
            <br />
            <p>
              If any questions or issues come up, please contact <a href="mailto:thomas.austin@sjsu.edu" className="text-red-600 font-semibold hover:underline">thomas.austin@sjsu.edu</a>.
            </p>
            {/* <p>

            </p>
            <br /> */}
            {/* <p>
                        <i>Note that we separately sent the instructions for a poster session through EasyChair, if you have any question, please contact us too. The poster author needs to submit your copyright form through the above Google form.</i>
                        </p> */}
          </Text>

        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
