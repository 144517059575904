import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import { FaAward } from "react-icons/fa";
// import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings";
const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)``;

// const TabControl = styled.div`
//   ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
//   &:hover {
//     ${tw`bg-gray-300 text-gray-700`}
//   }
//   ${props => props.active && tw`bg-primary-500! text-gray-100!`}
//   }
// `;
const HeadingSponsors = tw(
  SectionHeading
)`mt-4 font-black text-black text-left text-3xl sm:text-3xl lg:text-3xl text-center md:text-center leading-tight`;
const TabContent = tw(
  motion.div
)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(
  motion.a
)` rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`h-56 xl:h-64 bg-center bg-contain bg-no-repeat relative rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg text-center font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-center font-bold`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({
  heading = "",
  sponsors = [
    // {
    //   imageSrc:
    //   "https://upload.wikimedia.org/wikipedia/en/thumb/d/d3/SJSU_Seal.svg/1200px-SJSU_Seal.svg.png",
    //   title: "San Jose State Univeristy",
    //   type: "Gold",
    //   url:"https://www.sjsu.edu/",
    //   color:"text-yellow-500"
    // },
    {
      imageSrc:
        "https://1000logos.net/wp-content/uploads/2016/11/Cisco-logo-768x405.png",
      title: "Cisco",
      type: "Platinum",
      url: "https://www.cisco.com/",
      color: "text-gray-600",
    },
    {
      imageSrc:
        "https://upload.wikimedia.org/wikipedia/commons/3/38/Jabil_Circuit_Logo.svg",
      title: "Jabil",
      type: "Silver",
      url: "https://www.jabil.com/",
      color: "text-gray-700",
    },
    // {
    //   imageSrc:
    //     "https://upload.wikimedia.org/wikipedia/commons/thumb/0/00/San_Jose_State_University_logo.svg/640px-San_Jose_State_University_logo.svg.png",
    //   title: "San Jose State University",
    //   url: "https://www.sjsu.edu/",
    //   type: "Gold",
    //   color: "text-yellow-500",
    // },
    // {
    //   imageSrc:
    //     "https://upload.wikimedia.org/wikipedia/commons/6/6a/Intel_logo_%282020%2C_dark_blue%29.svg",
    //   title: "Intel",
    //   type: "Bronze",
    //   url: "https://www.intel.com/",
    //   color: "text-orange-800",
    // },
    // ,
    // {
    //   imageSrc:"https://www.selectgroup.com/wp-content/uploads/2017/04/select-group-logo-primary.png",
    //     // "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRaUmyxIfAC7jn-M1a_qBk-o9eQWnvqmXSf2yC3-hdzCSZdp17i-7OtAjmUykB33ZBzSsQ&usqp=CAU",
    //   title: "The Select Group",
    //   type: "Bronze",
    //   url: "https://www.selectgroup.com/",
    //   color: "text-orange-800",
    // },
    // {
    //   imageSrc:"https://www.csudh.edu/Assets/csudh-sites/brand/images/2018-06-18-csudh-logo-stacked-1-line-on-white.png",
    //   title: "CSUDH",
    //   type: "Bronze",
    //   url: "https://www.csudh.edu/",
    //   color: "text-orange-800",
    // },
    {
      imageSrc:
        "https://upload.wikimedia.org/wikipedia/commons/f/f4/Trend_Micro_logo.svg",
      title: "Trend-Micro",
      type: "Silver",
      url: "https://www.trendmicro.com/",
      color: "text-gray-700",
    },
  ],
}) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */

  const HeadingContainer = tw.div``;
  const Heading = tw(SectionHeading)``;

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {/* {subheading && <Subheading>{subheading}</Subheading>} */}
          {heading && <HeadingSponsors>{heading}</HeadingSponsors>}
          {/* {description && <Description>{description}</Description> } */}
        </HeadingContainer>
        <TabContent>
          {sponsors.map((sponsor, index) => (
            <CardContainer key={index}>
              <Card
                className="group"
                target="_blank"
                href={sponsor.url}
                initial="rest"
                whileHover="hover"
                animate="rest"
              >
                <CardImageContainer imageSrc={sponsor.imageSrc}>
                  <CardHoverOverlay
                    variants={{
                      hover: {
                        opacity: 1,
                        height: "auto",
                      },
                      rest: {
                        opacity: 0,
                        height: 0,
                      },
                    }}
                    transition={{ duration: 0.3 }}
                  >
                    <CardButton>Visit site</CardButton>
                  </CardHoverOverlay>
                </CardImageContainer>
                <CardText>
                  {/* <CardTitle>{sponsor.title}</CardTitle> */}
                  <CardContent className={sponsor.color}>
                    <span className="text-xl">{sponsor.type}</span>
                    <FaAward className="w-6 h-6 inline ml-2" />
                  </CardContent>
                  {/* <CardPrice>{card.price}</CardPrice> */}
                </CardText>
              </Card>
            </CardContainer>
          ))}
        </TabContent>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
