import React from "react";
import tw from "twin.macro";
import Head from "./Head";
import Header from "../../components/headers/light.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { ReactComponent as QuotesLeftIcon } from "../../images/quotes-l.svg";
import { ReactComponent as QuotesRightIcon } from "../../images/quotes-r.svg";
import { LinkedinFilled } from '@ant-design/icons';
import { Popover } from "antd";

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const QuoteContainer = tw.div`relative p-6 md:p-8 lg:p-10 mt-4 md:mt-0`;
        const Quote = tw.blockquote`text-center md:text-left font-medium text-xl lg:text-lg xl:text-lg`;
        const QuotesLeft = tw(QuotesLeftIcon)`w-8 h-8 lg:w-10 lg:h-10 text-primary-500 absolute top-0 left-0`;
        const QuotesRight = tw(QuotesRightIcon)`w-8 h-8 lg:w-10 lg:h-10 text-primary-500 absolute bottom-0 right-0`;
        return (
            <div>
                <AnimationRevealPage>
                    <Header />
                    <div className="mx-2">
                        <h1 style={{ "color": "#6415FF", fontSize: "3rem", fontWeight: "700", textAlign: "center", marginTop: "30px", marginLeft: "0px" }}>
                            IMPORTANT DATES
                            </h1>
                        <div class="min-h-screen bg-gray-100">
                            <div class="min-h-screen flex justify-center">
                                <div class="w-2/3 mx-auto">
                                    <div class="flex flex-row w-full">
                                        <div class="w-2/5 px-2 py-10">
                                            <div class="flex flex-col w-full rounded-lg shadow bg-white px-4 py-5">
                                                <div class="text-gray-600" style={{ textAlign: "center" }}>
                                                    <div class="font-bold">
                                                        Paper Submission Deadline
                                                        <br />
                                                        (<span style={{ color: "red" }}>Firm Deadline</span>)
                                                    </div>
                                                </div>
                                                <div style={{ fontSize: "1rem", textAlign: "center" }} className="text-xl">August 31, 2021 (By 11:59 P.M. in your own timezone)</div>
                                            </div>

                                        </div>
                                        <div class="w-1/5  flex justify-center">
                                            <div class="relative flex h-full w-1 bg-green-300 items-center justify-center">
                                                <div class="absolute font-bold flex flex-col justify-center h-24 w-24 rounded-full border-2 border-green-300 leading-none text-center z-10 bg-white font-thin" style={{ color: "red" }}>
                                                    <div>31</div>
                                                    <div>August</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-2/5 px-2 py-10 ">
                                        </div>
                                    </div>
                                    <div class="flex flex-row w-full">
                                        <div class="w-2/5 px-2 py-10">
                                        </div>
                                        <div class="w-1/5  flex justify-center">
                                            <div class="relative flex h-full w-1 bg-green-300 items-center justify-center">
                                                <div class="absolute  text-gray-600 flex flex-col justify-center h-24 w-24 rounded-full border-2 border-green-300 leading-none text-center z-10 bg-white">
                                                    <div>15</div>
                                                    <div>October</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-2/5 px-2 py-10 ">
                                            <div class="flex flex-col w-full rounded-lg shadow bg-white px-4 py-5">
                                                <div class="text-gray-600 ">
                                                    <div class="font-bold text-center" style={{ textAlign: "center" }}>
                                                        Author Notification
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-row w-full">
                                        <div class="w-2/5 px-2 py-10">
                                            <div class="flex flex-col w-full rounded-lg shadow bg-white px-4 py-5">
                                                <div class="text-gray-600">
                                                    <div class="font-bold" style={{ textAlign: "center" }}>
                                                        Camera-ready deadline
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-1/5  flex justify-center">
                                            <div class="relative flex h-full w-1 bg-green-300 items-center justify-center">
                                                <div class="absolute flex flex-col justify-center h-24 w-24 rounded-full border-2 border-green-300 leading-none text-center z-10 bg-white font-thin">
                                                    <div>15</div>
                                                    <div>November</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class="flex flex-row w-full">
                                        <div class="w-2/5 px-2 py-10">
                                        </div>
                                        <div class="w-1/5  flex justify-center">
                                            <div class="relative flex h-full w-1 bg-green-300 items-center justify-center">
                                                <div class="absolute  text-gray-600 flex flex-col justify-center h-24 w-24 rounded-full border-2 border-green-300 leading-none text-center z-10 bg-white font-thin">
                                                    <div>17-19</div>
                                                    <div>December</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-2/5 px-2 py-10 ">
                                            <div class="flex flex-col w-full rounded-lg shadow bg-white px-4 py-5">
                                                <div class="text-gray-600 ">
                                                    <div class="font-bold text-center" style={{ textAlign: "center" }}>
                                                        Conference Date
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </AnimationRevealPage>
            </div>
        );
    }
}

export default Main;